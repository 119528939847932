import React from "react"
import "./footer.scss"

import Twitter from "../../images/icons/twitter.svg"
import Facebook from "../../images/icons/facebook.svg"
import Youtube from "../../images/icons/youtube.svg"

const Footer = () => {
  return (
    <footer className={"footer"}>
      <div className="social-links">
        <a href="https://twitter.com" target={"_blank"} aria-label="Twitter">
          <Twitter />
        </a>
        <a href="https://youtube.com" target={"_blank"} aria-label="Youtube">
          <Youtube />
        </a>
        <a href="https://facebook.com" target={"_blank"} aria-label="Facebook">
          <Facebook />
        </a>
      </div>
      <div className="others-links">
        <a href="https://www.gatsbyjs.org/">{`© ${new Date().getFullYear()} TRVL`}</a>
        .<a href="https://www.gatsbyjs.org/">Site Terms </a>.
        <a href="https://www.gatsbyjs.org/">Service Terms </a>.
        <a href="https://www.gatsbyjs.org/">Privacy Policy </a>.
        <a href="https://www.gatsbyjs.org/"> Sitemap</a>
      </div>
      <form className="form" action="">
        <input
          className="input text"
          placeholder={"Subscribe"}
          aria-label="Subscribe"
          type="text"
        />
        <input className="input submit" value={"Send"} type="submit" />
      </form>
    </footer>
  )
}

export default Footer
